import React from 'react';
import { logo } from '../data';
import image from '../assets/img/TripoTripyLogo.png'

const Button = (props) => {
  return (
    
  

    <div>
      <section aria-labelledby="home" class="relative">
    <div class="flex flex-wrap-reverse gap-8 justify-center">
      <div class="flex gap-6 flex-wrap flex-col items-start lg:justify-center">
        <div class="flex flex-wrap flex-col items-start gap-2">
          <h1 class="text-5xl font-bold justify-center">Relax, you’re home!</h1>
          <h2 class="text-2xl font-italic">Find Out Our Reviews!</h2>
          <p class="max-w-md">
          "Discover what others are saying about our products and services with these reviews from our satisfied customers."
          </p>
        </div>
        <div class="
            py-2
            px-6
            bg-amber-400
            dark:text-neutral-900
            flex
            gap-2
            shadow-xl
            hover:shadow-none
            transition-shadow
            focus:outline-none
            focus-visible:ring-4
            ring-neutral-900
            rounded-md
            ring-offset-4
            ring-offset-white
            dark:ring-amber-400dark:ring-offset-neutral-800">
        <svg aria-hidden="true" class="w-5 h-5 text-white-400 text-decoration-line: none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
          <p class="ml-2 text-sm font-bold text-gray-900 dark:text-black text-decoration-line: none">4.8</p>
          <a href="https://goo.gl/maps/2ZM7PHvK9o3weV158?coh=178571&entry=tt" class="text-sm font-medium text-decoration-line: none text-gray-900 underline hover:no-underline dark:text-black">144 reviews</a>
      </div>
         
      </div>
      <img src={image} class="h-40 w-40" alt="TripoTripy"/>
    </div>
    <div 
      class="
        absolute
        -bottom-6
        -right-32
        -z-10
        aspect-square
        md:border-8
        border-amber-400
        md:w-64
        lg:w-96
        xl:max-w-lg
    "></div>
    {/* <div class=" bg-teal-900 h-24 xl:h-48 -mx-8 mt-12 xl:mt-0 xl:absolute w-screen -bottom-8 -z-10 "></div> */}
  </section>
  {/* <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" /> */}
    </div>

  )
}

export default Button