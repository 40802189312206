// import house images
import House1 from './assets/img/tripotripy.png';
import Kedarnath from './assets/img/kedarnath.jpeg';
import Kerala from './assets/img/kerla.jpeg';
import Kashmir from './assets/img/kashmir.jpeg';
import Himachal from './assets/img/himachal.jpeg';
import Spiti from './assets/img/spiti.jpeg';
import CharDham from './assets/img/chardham.jpeg';
import Manali from './assets/img/manali.jpeg';
import Coustomized from './assets/img/coustomized.jpeg';
import Kedarnathpdf from './assets/img/kedarnath.jpeg';
import TripoTripy from './assets/img/TripoTripyLogo.png'

// import house large images
import House1Lg from './assets/img/123.png';
import House2Lg from './assets/img/123.png';


// import agents images
import Agent1 from './assets/img/agents/agent1.png';
import Agent2 from './assets/img/agents/agent2.png';
import Agent6 from './assets/img/agents/agent6.png';

export const logo=[
  {
    image: TripoTripy,
  }
]

export const housesData = [
  {
    id: 1,
    type: 'Group Departure',
    name: 'KEDARNATH',
    description:
      'Kedarnath is a town located in the Rudraprayag district of the Indian state of Uttarakhand. It is a popular pilgrimage site for Hindus, who visit the Kedarnath Temple dedicated to Lord Shiva. The temple is located at an altitude of 3,583 meters in the Garhwal Himalayas and is considered one of the twelve Jyotirlingas of Lord Shiva.A tour of Kedarnath usually involves a trek through the mountains, passing through picturesque villages and breathtaking scenery. The trek to Kedarnath temple begins from Gaurikund, which is the base camp for the pilgrimage. From there, it is a 14 km trek through steep slopes and narrow paths. The trek can take up to six to eight hours, depending on one fitness level and speed. Upon reaching Kedarnath, visitors are greeted by the stunning view of the snow-capped Himalayan peaks surrounding the temple. The temple itself is made of large stone slabs and is more than a thousand years old. It is said that the temple was built by Adi Shankaracharya and has undergone several renovations over the years.Apart from the temple, Kedarnath offers several other attractions, such as the Vasuki Tal, Gandhi Sarovar, and the Kedarnath Wildlife Sanctuary. The Vasuki Tal is a beautiful lake surrounded by snow-capped mountains, while Gandhi Sarovar is a small lake that is believed to have been named after Mahatma Gandhi. The Kedarnath Wildlife Sanctuary is home to several rare and endangered species, such as the snow leopard, Himalayan black bear, and musk deer.Overall, a tour of Kedarnath offers a unique spiritual and cultural experience, as well as a chance to explore the natural beauty of the Himalayas. It is a journey that is not to be missed for anyone seeking adventure, serenity, and enlightenment.',
    image: Kedarnath,
    imageLg: Kedarnath,
    country: 'KEDARNATH',
    address: 'Embark on a Spiritual Journey to the Abode of Lord Shiva - Kedarnath Yatra',
    bedrooms: '9',
    bathrooms: '8',
    surface: '2000 km',
    year: '2023',
    price: '14500',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 2,
    type: 'Group Departure',
    name: 'HIMALAYA',
    description:
      'Himachal Pradesh is a beautiful state located in the northern part of India. It is often referred to as the "Land of Gods" due to its many temples and religious sites. The state is also home to stunning landscapes, including snow-capped mountains, lush valleys, and glistening rivers.A tour of Himachal Pradesh usually starts with a visit to Shimla, the state capital. Shimla is a popular hill station known for its colonial architecture, stunning vistas, and pleasant weather. Visitors can explore the city landmarks such as the Ridge, the Mall Road, and the Christ Church.Another popular destination in Himachal Pradesh is Manali, a beautiful hill station situated at the northern end of the Kullu Valley. Manali is famous for its scenic beauty, adventure sports, and ancient temples. Visitors can indulge in activities such as trekking, river rafting, skiing, and paragliding. Manali is also home to the Hadimba Devi Temple, a 16th-century temple dedicated to Goddess Hadimba.One of the most breathtaking destinations in Himachal Pradesh is the Spiti Valley, located at an altitude of 12,500 feet. The valley is known for its stark, barren landscapes, snow-capped mountains, and glistening lakes. Visitors can explore ancient monasteries such as the Ki Monastery and Tabo Monastery and indulge in activities such as trekking, camping, and wildlife spotting.Other popular destinations in Himachal Pradesh include Dharamshala, the spiritual home of the Dalai Lama, and the scenic town of Kasauli, known for its colonial architecture and serene atmosphere.Overall, a tour of Himachal Pradesh offers a unique blend of culture, adventure, and natural beauty. It is a journey that is not to be missed for anyone seeking to escape the hustle and bustle of city life and immerse themselves in the serene beauty of the Himalayas.',
    image: Himachal,
    imageLg: Himachal,
    country: 'HIMALAYAS',
    address: 'Experience the Majestic Beauty of the Himalayas and Get high on adventure and nature',
    bedrooms: '9',
    bathrooms: '8',
    surface: '2500 km',
    year: '2023',
    price: '14500',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 3,
    type: 'Group Departure',
    name: 'CHARDHAM',
    description:
      'Char Dham is a religious pilgrimage tour that is considered one of the most sacred journeys for Hindus. The tour takes visitors to four holy sites - Yamunotri, Gangotri, Kedarnath, and Badrinath, located in the northern part of India in the state of Uttarakhand.The journey begins with a visit to Yamunotri, which is the source of the Yamuna River. Visitors have to trek for about six kilometers from Janki Chatti to reach the temple of Yamunotri. The temple is surrounded by stunning natural beauty and is considered one of the most important Hindu pilgrimage sites.The next destination is Gangotri, which is the source of the Ganges River. Visitors have to trek for about 19 kilometers from Gangotri to reach the Gangotri temple. The temple is located in the picturesque surroundings of the Himalayas and is dedicated to Goddess Ganga.The third destination is Kedarnath, which is one of the twelve Jyotirlingas of Lord Shiva. Visitors have to trek for about 14 kilometers from Gaurikund to reach the Kedarnath temple. The temple is located at an altitude of 3,583 meters in the Garhwal Himalayas and offers stunning views of the surrounding peaks.The final destination of the Char Dham Yatra is Badrinath, which is located in the Chamoli district of Uttarakhand. The Badrinath temple is dedicated to Lord Vishnu and is considered one of the most important Hindu pilgrimage sites. Visitors can also explore the stunning natural beauty of the area, including the nearby Mana village, Vasudhara Falls, and the Valley of Flowers.Overall, the Char Dham Yatra is a journey of a lifetime for anyone seeking spiritual enlightenment and a deeper understanding of Hinduism. The tour offers stunning natural beauty, cultural experiences, and an opportunity to explore the rich spiritual heritage of India. ',
    image: CharDham,
    imageLg: CharDham,
    country: 'CHARDHAM',
    address: 'Embark on a spiritual journey of a lifetime with Chardham Yatra - where faith meets adventure!',
    bedrooms: '9',
    bathrooms: '8',
    surface: '4200 km',
    year: '2023',
    price: '28000',
    pdf:Kedarnathpdf,
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 4,
    type: 'Group Departure',
    name: 'MANALI',
    description:
      'Manali is a charming hill station located in the northern state of Himachal Pradesh in India. It is nestled in the foothills of the Himalayas and is a popular destination for adventure enthusiasts, nature lovers, and honeymooners alike.A tour of Manali usually starts with a visit to the famous Hadimba Temple, a 16th-century temple dedicated to Goddess Hadimba. The temple is surrounded by lush green forests and is a popular spot for tourists and locals alike. Visitors can also explore the nearby Manu Temple, which is dedicated to the sage Manu and is believed to be the only temple dedicated to him in India.Manali is also famous for its scenic beauty and adventure sports. Visitors can indulge in activities such as trekking, mountain biking, skiing, snowboarding, and paragliding. The town is surrounded by snow-capped mountains, glistening rivers, and lush valleys, offering breathtaking views and a serene atmosphere.Another popular attraction in Manali is the Rohtang Pass, a high mountain pass located at an altitude of 3,979 meters above sea level. The pass offers stunning views of the surrounding mountains and valleys and is a popular spot for adventure activities such as skiing and snowboarding.For those seeking a more relaxed experience, Manali offers several hot springs and spas where visitors can unwind and rejuvenate. The Vashisht Hot Springs, located near the Vashisht Temple, is a popular spot for its therapeutic properties.Overall, a tour of Manali offers a unique blend of adventure, culture, and natural beauty. It is a journey that is not to be missed for anyone seeking to escape the chaos of city life and immerse themselves in the serene beauty of the Himalayas.',
    image: Manali,
    imageLg: Manali,
    country: 'MANALI',
    address: 'Experience the breathtaking beauty of Manali, where adventure meets serenity.',
    bedrooms: '8',
    bathrooms: '7',
    surface: '3000 km',
    year: '2023',
    price: '15000',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 5,
    type: 'COUPLE PACKAGE',
    name: 'KASHMIR',
    description:
      'Kashmir is a beautiful region in the northernmost part of India, known for its breathtaking landscapes, vibrant culture, and warm hospitality. The region is often referred to as "Paradise on Earth" due to its stunning natural beauty.A tour of Kashmir usually starts with a visit to Srinagar, the state capital. Srinagar is famous for its serene lakes, beautiful gardens, and stunning Mughal-era architecture. Visitors can take a leisurely boat ride on the Dal Lake, explore the famous Mughal Gardens such as Shalimar Bagh and Nishat Bagh, and indulge in delicious Kashmiri cuisine.Another popular destination in Kashmir is Gulmarg, a picturesque hill station known for its scenic beauty and adventure sports. Visitors can enjoy skiing, snowboarding, and cable car rides to experience the stunning views of the snow-capped mountains.Pahalgam is another popular destination in Kashmir, known for its lush valleys, glistening rivers, and beautiful meadows. Visitors can indulge in activities such as trekking, camping, and rafting while exploring the stunning natural beauty of the region.One of the most popular attractions in Kashmir is the world-famous Shikara ride on the Dal Lake. Visitors can take a leisurely boat ride on a traditional Kashmiri Shikara while admiring the stunning views of the surrounding mountains and lakeside gardens.Overall, a tour of Kashmir offers a unique blend of natural beauty, vibrant culture, and warm hospitality. It is a journey that is not to be missed for anyone seeking to explore the stunning landscapes of India and immerse themselves in the rich culture of the region.',
    image: Kashmir,
    imageLg: Kashmir,
    country: 'KASHMIR',
    address: 'Discover the Paradise on Earth .Get lost in the magic of Beauty and Culture Kashmir',
    bedrooms: '9',
    bathrooms: '8',
    surface: '3000 km',
    year: '2023',
    price: '18000',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 6,
    type: 'Group Departure',
    name: 'SPITI',
    description:
      'Spiti Valley is a cold desert mountain valley located in the Indian state of Himachal Pradesh. It is a remote destination known for its breathtaking landscapes, ancient monasteries, and vibrant culture. A tour of Spiti usually involves a journey through winding roads, high mountain passes, and rugged terrains.The Spiti Valley is home to several picturesque villages, each offering a unique glimpse into the local way of life. Visitors can explore the villages of Kibber, Langza, and Dhankar, where they can interact with the locals, witness traditional ceremonies, and visit ancient monasteries.One of the most popular attractions in Spiti is the Ki Monastery, an ancient Tibetan Buddhist monastery located on top of a hill overlooking the valley. The monastery is home to a large collection of ancient artifacts, thangkas, and manuscripts.Another popular destination in Spiti is the Pin Valley National Park, a wildlife sanctuary that is home to several endangered species, such as the snow leopard, Siberian ibex, and Himalayan griffon. Visitors can also explore the stunning landscape of the park, including the Pin River and the soaring peaks of the Himalayas.The Spiti Valley is also known for its adventure activities, including trekking, camping, and mountain biking. Visitors can trek through the valley, passing through remote villages, ancient monasteries, and breathtaking landscapes. They can also camp under the stars and immerse themselves in the serene beauty of the valley.Overall, a tour of Spiti Valley offers a unique opportunity to explore a remote and beautiful destination that is rich in culture, history, and natural beauty. It is a journey that is not to be missed for anyone seeking to escape the hustle and bustle of city life and immerse themselves in the serene beauty of the Himalayas.',
    image: Spiti,
    imageLg: Spiti,
    country: 'SPITI',
    address: 'Discover the untouched beauty of Spiti Valley - Where Adventure Meets Serenity',
    bedrooms: '9',
    bathrooms: '8',
    surface: '2200 km',
    year: '2023',
    price: '18500',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 7,
    type: 'COUPLE PACKAGE',
    name: 'KERALA',
    description:
      'Kerala is a beautiful state located in the southwestern part of India. It is often referred to as God Own Country due to its serene beauty, lush green landscapes, and peaceful environment. Kerala is famous for its backwaters, beaches, and hill stations, making it a popular tourist destination in India.A tour of Kerala usually starts with a visit to Kochi, a city known for its colonial history, vibrant culture, and picturesque backwaters. Visitors can explore the Fort Kochi area, which is home to several historic landmarks such as the Dutch Palace, Jewish Synagogue, and St. Francis Church. They can also enjoy a scenic boat ride through the famous backwaters of Kochi.Another popular destination in Kerala is Munnar, a scenic hill station located at an altitude of 1,600 meters above sea level. Munnar is known for its sprawling tea plantations, lush green forests, and picturesque valleys. Visitors can explore the tea estates, trek through the forests, and enjoy the stunning views of the Western Ghats.The backwaters of Alleppey are another popular attraction in Kerala. Visitors can take a houseboat ride through the backwaters and enjoy the serene beauty of the lush green landscapes, coconut groves, and villages along the banks of the backwaters.Kerala is also home to several pristine beaches such as Kovalam, Varkala, and Cherai. Visitors can relax on the sandy beaches, indulge in water sports, and enjoy the stunning sunsets over the Arabian Sea.Overall, a tour of Kerala offers a unique blend of culture, nature, and serenity. It is a journey that is not to be missed for anyone seeking to escape the hustle and bustle of city life and immerse themselves in the peaceful beauty of God Own Country.',
    image: Kerala,
    imageLg: Kerala,
    country: 'KERALA',
    address: 'Discover the Serene Beauty of "God\'s Own Country" - Kerala',
    bedrooms: '9',
    bathrooms: '8',
    surface: '1000 km',
    year: '2023',
    price: '20000',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
  {
    id: 8,
    type: 'CUSTOMISE',
    name: 'CUSTOMISE',
    description:
      'Customised tours are a popular way for travelers to explore their dream destinations in a way that is tailored to their unique interests, preferences, and budget. Whether it\'s a romantic honeymoon, a family vacation, or an adventure-packed trip, customised tours offer flexibility and personalisation, allowing travelers to design their ideal itinerary and create memories that will last a lifetime.At our travel agency, we offer customised tours that cater to every traveler\'s needs and desires. Our team of experienced travel experts work closely with our clients to understand their interests and preferences, and design an itinerary that perfectly suits their requirements. From luxury accommodations to adventurous activities, we take care of every detail, ensuring that our clients have a hassle-free and enjoyable experience.Our customised tours offer a wide range of options, including cultural tours, adventure tours, wildlife safaris, beach vacations, and more. Whether you want to explore the ancient temples of Asia, go on a wildlife safari in Africa, or relax on a secluded beach in the Caribbean, we have the perfect itinerary for you.We believe that travel is not just about visiting new places but also about experiencing different cultures, cuisines, and ways of life. Therefore, we offer unique and immersive experiences that allow travelers to interact with locals, learn about their customs and traditions, and gain a deeper understanding of the destination.In conclusion, our customised tours offer the ultimate travel experience, allowing you to explore the world on your terms. Let us help you design your dream vacation and create memories that will last a lifetime.      ',
    image: Coustomized,
    imageLg: Coustomized,
    country: 'CUSTOMISE',
    address: 'Craft your perfect adventure with our customized tours',
    bedrooms: '1-10',
    bathrooms: '1-10',
    surface: 'DEPENDS',
    year: '2023',
    price: 'DEPENDS ON PACKAGE',
    agent: {
      image: Agent1,
      name: 'GIRIJESH GUPTA',
      phone: '9324336548',
    },
  },
];
