import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

//import the images for the imageslider
import img1 from '../assets/tours/0e7c6239-4ca8-4b20-97e5-c5e650936db5.jpg';
import img2 from '../assets/tours/1672901286968.jpg';
import img3 from '../assets/tours/2021-09-30.jpg';
import img4 from '../assets/tours/2022-09-30.jpg';
import img5 from '../assets/tours/2022-12-17.jpg';
import img6 from '../assets/tours/2022-12-20.jpg';
import img7 from '../assets/tours/20221125_153056.jpg';
import img8 from '../assets/tours/8f8994ed-c144-4e97-a0e4-9fd733bef149.jpg';
import img9 from '../assets/tours/9e144c88-915b-4d51-84cb-0e25493f1bed.jpg';
import img10 from '../assets/tours/IMG-20221125-WA0149.jpg';
import img11 from '../assets/tours/ce9e9cbb-0211-47a5-97e0-61998e85a0f8.jpg';
import img12 from '../assets/tours/IMG_4942.JPG';
import img13 from '../assets/tours/IMG-20221128-WA0005.jpg';
import img14 from '../assets/tours/IMG20221124155009.jpg';
import img15 from '../assets/tours/IMG20221125121424.jpg';
import chardham from '../assets/tours/chardham.jpg';
import chardham1 from '../assets/tours/chardham-1.jpg';
import chardham2 from '../assets/tours/chardham-2.jpg';
import chardham3 from '../assets/tours/chardham-3.jpg';

function ImageSlider() {
  const slides = [
    {
      url:chardham1
    },
    {
      url:chardham
    },
    {
      url:chardham2
    },
    {
      url:chardham3
    },
    {
      url: img12,
    },
    {
      url: img2,
    },
    {
      url:img3,
    },
    {
      url: img4,
    },
    {
      url:img5,
    },
    {
      url:img6,
    },
    {
      url:img7,
    },
    {
      url:img8,
    },
    {
      url:img9,
    },
    {
      url:img10,
    },
    {
      url:img11,
    },
    {
      url:img1,
    },
    {
      url: img13
    },
    {
      url: img14
    },
    {
      url: img15
    },
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 relative group'>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className='w-full h-full rounded-2xl bg-center bg-cover duration-100'
      ></div>
      {/* Left Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex top-4 justify-center py-2'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
