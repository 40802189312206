import React from 'react';

// import components
import HouseList from '../components/HouseList';
import Banner from '../components/Banner';
import Rating from '../components/Review';
import ImageSlider from '../components/ImageSlider';

const Home = () => {
  return (
    <div className='min-h-[1800px]'>

      {/* <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" /> */}
      <Banner />
      <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
      <HouseList />
      <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
      <Rating/>
      <hr class="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
      <ImageSlider/>
      
    </div>
  );
};

export default Home;
