import React from 'react';

// import link
import { Link } from 'react-router-dom';
// import logo
// import Logo from '../assets/img/TripoTripyLogo.png';
import Logo from '../assets/img/logo2.png';
const Header = () => {
  return (
    <header className='py-6 mb-12 border-b'>
       
      <div className='container mx-auto flex justify-between items-center'>
       
        <Link to='/'>
          <img src={Logo} alt='' className='px-4 py-3 w-200 h-25'/>
          {/* TripoTripy */}
        </Link>
        {/* TripoTripy */}
        <div className='flex items-center gap-6'>
            <a href="https://tripotripyabout.netlify.app" className='hover:text-violet-900 transition'>
              About
            </a>
            <a className='hover:text-violet-900 transition' href='https://wa.me/9324336548' > 
            Contact
            </a>  
            
          {/* </Link> */}
          <a className='bg-violet-700 hover:bg-violet-800 text-white px-4 py-3 rounded-lg transition' href='https://www.instagram.com/tripotripy/'>Instagram</a>
         
        </div>
      </div>
    </header>
  );
};

export default Header;

