import React from 'react';

import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageSlider from './components/ImageSlider.jsx';
import About from './components/about';

// import pages
import Home from './pages/Home';
import PropertyDetails from './pages/PropertyDetails';
// import { ImInstagram } from 'react-icons/im';
// import Banner from './components/Banner';

const App = () => {
  return (
    <div className='max-w-[1440px] mx-auto bg-white'>
      <Header />
      {/* <Banner/> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/property/:id' element={<PropertyDetails />} />
        <Route path='/imageslider' element={<ImageSlider />} />
        <Route path='about' element={<About />} />
      </Routes>
      {/* <ImageSlider /> */}
      {/* <About/> */}
      <Footer />
    </div>
  );
};

export default App;
